import {
  USER_REQUEST_LOGIN_PENDING,
  USER_REQUEST_LOGIN_SUCCESS,
  USER_REQUEST_LOGIN_ERROR,
  RESET_USER_STATE,
  USER_REQUEST_LOGOUT,
  GET_NOTIFICATIONS_PENDING,
  GET_NOTIFICATIONS_SUCCESS,
  GET_NOTIFICATIONS_ERROR,
  SET_NOTIFICATION_COUNT,
  RESET_NOTIFICATION_COUNT,
  RESET_NOTIFICATIONS,
  //SET_LANGUAGE, SET_TOUR_INFO,
  SET_USER_DETAILS,
  GET_PATH,
  INITIALIZE_STATE,
  TOGGLE_NOMINAL,
  TOGGLE_ALL_BRAND,
  TOGGLE_LIVE_AREAS,
  USER_PERMISSIONS_PENDING,
  USER_PERMISSIONS_SUCCESS,
  USER_PERMISSIONS_ERROR,
  SET_CAPTCHA,
  SET_LAST_FETCHED_NOTIFICATION_COUNT,
  RESET_POPUP_NOTIFICATIONS,
  GET_POPUP_NOTIFICATIONS_SUCCESS,
  GET_POPUP_NOTIFICATIONS_ERROR,
  GET_POPUP_NOTIFICATIONS_PENDING,
  SET_LAST_FETCHED_POPUP_NOTIFICATION_COUNT,
  RESET_ALL_NOTIFICATIONS_DATA
} from './types';
import {
  Login,
  UserDetails,
  SetGlobalLang,
  TourInfoSet,
  GetNotificationSummary,
  SeenNotificationSummary,
  GetNotificationCount,
  PerformanceTableFilter,
  UserPermissions,
  SetPanelConfig,
  SetUserNotificationSeen
} from '../Helper/api';
import setAuth, { AuthToken } from '../Helper/setAuth';
import { dispatch as d, dispatch } from '../ReduxStore';
import SignalR, { startGlobalConnection } from '../Helper/SignalR';
import { ZohoRemove } from '../Containers/zohoPlugin';
import { removeFCMToken } from '../Helper/fcmApis';
import { AES, enc } from 'crypto-js';
import { setParam } from './paramsActions';
import Modules from '../Contexts/Modules';
import Roles from '../Contexts/Roles';
import Permissions from '../Contexts/Permissions';
import { PAGE_SIZE } from '../Components/Notification';
import { NotificationSenderType } from '../Constants';
export var asyncInitialize = function () {
  return new Promise(resolve => {
    d({
      type: INITIALIZE_STATE
    });
    resolve(true);
  });
};

export const loginUser = (username, password, captcha_value) => dispatch => {
  asyncInitialize().then(() => {
    dispatch({
      type: USER_REQUEST_LOGIN_PENDING
    });
    Login(username, password, captcha_value)
      .then(res => {
        setAuth(new AuthToken(res.access_token)).then(async r => {
          const userSecret = { username, password };
          const secret = AES.encrypt(
            JSON.stringify(userSecret),
            'secret key 123'
          ).toString();
          dispatch({
            type: USER_REQUEST_LOGIN_SUCCESS,
            payload: res
          });
          dispatch(getDetails(secret));
        });
      })
      .catch(err => {
        dispatch({
          type: USER_REQUEST_LOGIN_ERROR,
          payload: err.response.data.error_description
        });
        if (err.response.headers.capctha_required === 'true') {
          dispatch({
            type: SET_CAPTCHA,
            payload: {
              image: err.response.headers.capctha_imagebase64,
              required: err.response.headers.capctha_required,
              expire: err.response.headers.capctha_expire
            }
          });
        }
      });
  });
};

export const logoutUser = () => async dispatch => {
  await removeFCMToken(localStorage.getItem('fcmToken'));
  SignalR.Disconnect();
  // ZohoRemove();
  dispatch({
    type: USER_REQUEST_LOGOUT
  });
};
export const resetUserState = () => dispatch => {
  dispatch({
    type: RESET_USER_STATE
  });
};

export const timeOutLogoutUser = () => async dispatch => {
  SignalR.Disconnect();
  // ZohoRemove();
  dispatch({
    type: USER_REQUEST_LOGOUT
  });
};

export async function Relogin() {
  const secret = localStorage.getItem('UP');

  if (!secret) {
    d(timeOutLogoutUser());
    return Promise.reject('Relogin Error');
  }

  const bytes = AES.decrypt(secret, 'secret key 123');
  const { username, password } = JSON.parse(bytes.toString(enc.Utf8));

  setAuth(new AuthToken()).catch(err => {});

  return Login(username, password)
    .then(res => {
      return setAuth(new AuthToken(res.access_token)).then(async () => {
        try {
          d({
            type: USER_REQUEST_LOGIN_SUCCESS,
            payload: res
          });
          d(getDetails());
          startGlobalConnection(res.access_token);
        } catch (err) {}
      });
    })
    .catch(err => {
      console.log(err);
      d(timeOutLogoutUser());
      return Promise.reject(err);
    });
}

export const setLang = lang => dispatch => {
  SetGlobalLang(lang).then(r => dispatch(getDetails()));
};
export const SetTourInfo = payload => dispatch => {
  TourInfoSet(payload).then(r => dispatch(getDetails()));
};

export function getPath(p) {
  return dispatch => {
    dispatch({
      type: GET_PATH,
      payload: p
    });
  };
}
export const getDetails = UP => dispatch => {
  UserDetails().then(userDetails => {
    if (
      userDetails.Roles[0] === Roles.IsApiClient ||
      userDetails.Module === Modules.Reservation
    ) {
      dispatch(logoutUser());
    }
    if (UP) {
      localStorage.setItem('UP', UP);
    }
    dispatch({
      type: SET_USER_DETAILS,
      payload: userDetails
    });
    dispatch(setParam('brandId', userDetails.BrandId));
    dispatch(setParam('brandID', userDetails.BrandId));
  });
};

export const getPermissions = () => dispatch => {
  dispatch({
    type: USER_PERMISSIONS_PENDING
  });
  UserPermissions()
    .then(res => {
      if (!('Permissions' in res)) {
        res.Permissions = Object.keys(Permissions).map(permissionKey => ({
          Name: Permissions[permissionKey]
        }));
      }
      dispatch({
        type: USER_PERMISSIONS_SUCCESS,
        payload: res
      });
    })
    .catch(err => {
      dispatch({
        type: USER_PERMISSIONS_ERROR,
        payload: err
      });
    });
};

export const getPopupNotifications =
  (p, resetNotifications, lastFetchedNotificationCount) =>
  (dispatch, getState) => {
    dispatch({
      type: GET_POPUP_NOTIFICATIONS_PENDING
    });
    GetNotificationSummary(p)
      .then(res => {
        const newlySeenNotifications = res?.Data?.filter(
          notification => !notification.SeenDate
        ).map(notification => ({
          NotificationId: notification.Id,
          RuleSetId: notification.RuleSetId,
          NotificationSenderType: NotificationSenderType.Web
        }));

        if (
          Array.isArray(newlySeenNotifications) &&
          newlySeenNotifications.length > 0
        ) {
          dispatch(seenPopupNotifications(newlySeenNotifications));
        }

        const prevNotifications = getState()?.user?.Notifications?.data ?? [];

        const payload = res?.Data?.filter(
          notification =>
            resetNotifications ||
            !prevNotifications.some(
              prevNotification => prevNotification.Id === notification.Id
            )
        ).map(notification => ({
          ...notification,
          PageIndex: res.PageIndex
        }));

        if (resetNotifications) {
          dispatch({
            type: RESET_POPUP_NOTIFICATIONS,
            payload
          });
        } else {
          dispatch({
            type: GET_POPUP_NOTIFICATIONS_SUCCESS,
            payload,
            atLast: res?.Data?.length !== PAGE_SIZE,
            lastFetchedNotificationCount
          });
        }
      })
      .catch(res => {
        dispatch({
          type: GET_POPUP_NOTIFICATIONS_ERROR,
          payload: res
        });
      });
  };

export const getPopupNotificationInitCount = payload => dispatch => {
  GetNotificationCount().then(res => {
    dispatch({
      type: SET_NOTIFICATION_COUNT,
      payload: res
    });

    dispatch({
      type: SET_LAST_FETCHED_POPUP_NOTIFICATION_COUNT,
      payload: res
    });
  });
};

export const seenPopupNotifications = SeenNotif => dispatch => {
  SetUserNotificationSeen(SeenNotif).then(res => {
    dispatch(getPopupNotificationInitCount());
  });
};

export const getNotifications =
  (p, resetNotifications, lastFetchedNotificationCount) =>
  (dispatch, getState) => {
    dispatch({
      type: GET_NOTIFICATIONS_PENDING
    });
    GetNotificationSummary(p)
      .then(res => {
        const newlySeenNotifications = res?.Data?.filter(
          notification => !notification.SeenDate
        ).map(notification => ({
          NotificationId: notification.Id,
          RuleSetId: notification.RuleSetId,
          NotificationSenderType: NotificationSenderType.Web
        }));

        if (
          Array.isArray(newlySeenNotifications) &&
          newlySeenNotifications.length > 0
        ) {
          dispatch(seenNotifications(newlySeenNotifications));
        }

        const prevNotifications = getState()?.user?.Notifications?.data ?? [];

        const payload = res?.Data?.filter(
          notification =>
            resetNotifications ||
            !prevNotifications.some(
              prevNotification => prevNotification.Id === notification.Id
            )
        ).map(notification => ({
          ...notification,
          PageIndex: res.PageIndex
        }));

        if (resetNotifications) {
          dispatch({
            type: RESET_NOTIFICATIONS,
            payload
          });
        } else {
          dispatch({
            type: GET_NOTIFICATIONS_SUCCESS,
            payload,
            atLast: res?.Data?.length !== PAGE_SIZE,
            lastFetchedNotificationCount
          });
        }
      })
      .catch(res => {
        dispatch({
          type: GET_NOTIFICATIONS_ERROR,
          payload: res
        });
      });
  };

export const setNotificationCount = payload => dispatch => {
  dispatch({
    type: SET_NOTIFICATION_COUNT,
    payload: payload
  });
};

export const getNotificationInitCount = payload => dispatch => {
  GetNotificationCount().then(res => {
    dispatch({
      type: SET_NOTIFICATION_COUNT,
      payload: res
    });

    dispatch({
      type: SET_LAST_FETCHED_NOTIFICATION_COUNT,
      payload: res
    });
  });
};

export const seenNotifications = SeenNotif => dispatch => {
  SetUserNotificationSeen(SeenNotif).then(res => {
    dispatch(getNotificationInitCount());
  });
};
export const resetNotifCount = () => dispatch => {
  dispatch({
    type: RESET_NOTIFICATION_COUNT
  });
};

export const resetAllNotificationsData = () => dispatch => {
  dispatch({
    type: RESET_ALL_NOTIFICATIONS_DATA
  });
};

export const SetPerformanceTableFilter = payload => dispatch => {
  PerformanceTableFilter(payload).then(r => dispatch(getDetails()));
};

export const SetTableFilter = payload => dispatch => {
  SetPanelConfig(payload).then(r => dispatch(getDetails()));
};

export const toggleNominal = () => {
  return {
    type: TOGGLE_NOMINAL
  };
};

export const toggleAllBrand = () => {
  return {
    type: TOGGLE_ALL_BRAND
  };
};

export const toggleShowLiveAreas = () => {
  return {
    type: TOGGLE_LIVE_AREAS
  };
};
